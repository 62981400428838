// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".getQuote_1dHGi{border-radius:5px;--tw-bg-opacity:1;background-color:rgba(247, 247, 247, 1);background-color:rgba(247, 247, 247, var(--tw-bg-opacity));padding:15px}.getQuote_1dHGi *{font-size:1.125rem;font-weight:700;line-height:1.5rem}.getQuote_1dHGi a{margin-top:15px;display:block;text-align:left;font-weight:700;--tw-text-opacity:1;color:rgba(255, 255, 255, 1);color:rgba(255, 255, 255, var(--tw-text-opacity));text-decoration-line:none;padding:8px 25px;border-radius:3px;border:1px solid rgba(0,0,0,0);background-color:#41a5dd}.getQuote_1dHGi a:hover{border-color:#333}@media(min-width: 1270px){.getQuote_1dHGi *{font-size:1rem;font-weight:400;line-height:1.375rem}.getQuote_1dHGi a{text-align:center}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"getQuote": "getQuote_1dHGi"
};
module.exports = ___CSS_LOADER_EXPORT___;
