
import { Vue, Component, Prop, mixins } from 'nuxt-property-decorator'
import ImageUrlS3 from '~/mixins/imageUrlS3'

@Component
export default class PageLayoutRightSidebar extends mixins(ImageUrlS3) {
  @Prop({ required: true }) page: Record<string, any>

  isAdviceCategory() {
    const category = this.page.categories.nodes.find(
      (category) => category.slug === 'advice-index'
    )

    return !!category
  }

  isBlogCategory() {
    const category = this.page.categories.nodes.find(
      (category) => category.slug === 'blog-index'
    )

    return !!category
  }
}
